import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: "/",
        redirect: "/home"
    },
    {
        path: '/home',
        name: 'home',
        component: () => import('../views/home')
    },
    {
        path: "/cn",
        redirect: "/cn/home"
    },
    {
        path: '/cn/home',
        name: 'home-cn',
        component: () => import('../views/home-cn')
    },
    {
        path: '/product/Blue-Classic',
        name: 'classic',
        component: () => import('../views/classic')
    },
    {
        path: '/cn/product/Blue-Classic',
        name: 'classic-cn',
        component: () => import('../views/classic-cn')
    },
    {
        path: '/product/Dream-of-Blue-Crystal-Edition',
        name: 'crystal',
        component: () => import('../views/crystal')
    },
    {
        path: '/cn/product/Dream-of-Blue-Crystal-Edition',
        name: 'crystal-cn',
        component: () => import('../views/crystal-cn')
    },
    {
        path: '/product/Dream-of-Blue-Handmade-Edition',
        name: 'handmade',
        component: () => import('../views/handmade')
    },
    {
        path: '/cn/product/Dream-of-Blue-Handmade-Edition',
        name: 'handmade-cn',
        component: () => import('../views/handmade-cn')
    },
    {
        path: '/product/Dream-of-Blue-M6+',
        name: 'm6plus',
        component: () => import('../views/m6plus')
    },
    {
        path: '/cn/product/Dream-of-Blue-M6+',
        name: 'm6plus-cn',
        component: () => import('../views/m6plus-cn')
    },
    {
        path: '/product/Dream-of-Blue-M9',
        name: 'm9',
        component: () => import('../views/m9')
    },
    {
        path: '/cn/product/Dream-of-Blue-M9',
        name: 'm9-cn',
        component: () => import('../views/m9-cn')
    },
    {
        path: '/product/Micro-Molecules',
        name: 'molecules',
        component: () => import('../views/molecules')
    },
    {
        path: '/cn/product/Micro-Molecules',
        name: 'molecules-cn',
        component: () => import('../views/molecules-cn')
    }, {
        path: '/product/Yanghe-Daqu-New-Sky-Blue',
        name: 'skyblue',
        component: () => import('../views/skyblue')
    },
    {
        path: '/cn/product/Yanghe-Daqu-New-Sky-Blue',
        name: 'skyblue-cn',
        component: () => import('../views/skyblue-cn')
    },
]

const router = new VueRouter({
    mode: "hash",
    base: "/yanghe/",
    routes
})

export default router
