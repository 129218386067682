import Vue from 'vue';
import App from './App.vue';
import router from './router';
import 'amfe-flexible';

Vue.config.productionTip = false

Vue.mixin({
    data() {
        return {
            $scrollOptions: {
                container: "body",
                easing: "ease",
                lazy: false,
                offset: 0,
                force: true,
                cancelable: true,
                onStart: false,
                onDone: false,
                onCancel: false,
                x: false,
                y: true
            }
        }
    }
});

new Vue({
    router,
    render: h => h(App)
}).$mount('#app');
